const Fleece = () => {
  return (
    <>
      <div id="Fleece" className="Eco__product">
        <h2 className="Eco__title">Blusas Fleece</h2>
        <div className="Eco__grid">
          <div className="Eco__grid--left">
            <img
              className="Eco__image"
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner3--desk.png"
              alt="Blusas Fleece Uso"
            />
            <p className="Eco__paragraph">
              A gente sabe que uma garrafa de plástico vazia não tem valor, mas
              quando damos um novo destino para ela, tudo muda!
            </p>
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icons.png"
              className="Eco__image"
              alt="Icones Processo Reciclagem"
            />
            <p className="Eco__paragraph">
              Entenda melhor sobre o processo de reciclagem:
            </p>
          </div>
          <div className="Eco__grid--right">
            <p className="Eco__paragraph">
              Pensadas para potencializar o aquecimento do corpo durante a
              prática de exercício, nossas blusas de fleece possuem um tecido
              confortável, com secagem rápida e eliminam a transpiração, ou
              seja: permite que seu corpo permaneça quente e seco.
            </p>
            <p className="Eco__paragraph">
              Mas, você sabia que 60% das nossas blusas de fleece já foram uma
              garrafa plástica? Pois é! Esses produtos são feitos de 100% de
              poliéster reciclado, a partir da reciclagem de garrafas plásticas.
            </p>
            <img
              className="Eco__image"
              alt="Blusas Fleece"
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__product2.png"
            />
            <a
              href="https://www.decathlon.com.br/pesquisa/?q=fleece"
              className="Eco__goToProduct left"
            >
              ver produto
            </a>
            <div className="Eco__video">
              <iframe
                width="100%"
                height="320"
                src="https://www.youtube.com/embed/WVOCQvBvpHY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
                title="Blusas fleece"
              />
            </div>
          </div>
        </div>
        <div className="Eco__gloves">
          <p className="Eco__paragraph center">
            Além disso, nossa
            <strong className="Eco__strong">Luvas Trek 100</strong> também
            passam pelo mesmo processo de ecodesign e são feitas 100% de
            poliéster reciclado.
          </p>
          <p className="Eco__paragraph center">
            Leves, elas proporcionam aquecimento em temperaturas até a 5°C e
            permitem a eliminação da umidade nas mãos.
          </p>
          <img
            className="Eco__image Eco__gloves--image"
            alt="Luvas Trek 100"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__gloves.png"
          />
          <a
            href="https://www.decathlon.com.br/luvas-trekking-viagem-explor-100-preto/p"
            className="Eco__goToProduct center"
          >
            ver produto
          </a>
        </div>
      </div>
      <section id="fleece" className="EcoLP__product">
        <h2 className="EcoLP__title">Blusas Fleece</h2>
        <img
          className="EcoLP__image"
          src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner3.png"
          alt=""
        />
        <p className="EcoLP__paragraph">
          Pensadas para potencializar o aquecimento do corpo durante a prática
          de exercício, nossas blusas de fleece possuem um tecido confortável,
          com secagem rápida e eliminam a transpiração, ou seja: permite que seu
          corpo permaneça quente e seco.
        </p>
        <p className="EcoLP__paragraph">
          Mas, você sabia que 60% das nossas blusas de fleece já foram uma
          garrafa plástica? Pois é! Esses produtos são feitos de 100% de
          poliéster reciclado, a partir da reciclagem de garrafas plásticas.
        </p>
        <div className="EcoLP__seeProduct">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__product2.png"
            alt="Blusa Fleece"
          />
          <a
            href="https://www.decathlon.com.br/pesquisa/?q=fleece"
            className="EcoLP__goToProduct left"
          >
            Ver Produto
          </a>
        </div>
        <p className="EcoLP__paragraph">
          A gente sabe que uma garrafa de plástico vazia não tem valor, mas
          quando damos um novo destino para ela, tudo muda!
        </p>
        <img
          className="EcoLP__image"
          src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icons.png"
          alt=""
        />
        <p className="EcoLP__paragraph">
          Entenda melhor sobre o processo de reciclagem:
        </p>
        <div className="EcoLP__video">
          <iframe
            width="100%"
            height="220"
            src="https://www.youtube.com/embed/WVOCQvBvpHY"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={false}
            title="Blusas fleece"
          />
        </div>
        <div className="EcoLP__flex">
          <div className="EcoLP__text">
            <p className="EcoLP__paragraph">
              Além disso, nossa
              <strong className="EcoLP__strong">Luvas Trek 100</strong> também
              passam pelo mesmo processo de ecodesign e são feitas 100% de
              poliéster reciclado.
            </p>
            <p className="EcoLP__paragraph">
              Leves, elas proporcionam aquecimento em temperaturas até a 5°C e
              permitem a eliminação da umidade nas mãos.
            </p>
          </div>
          <div className="EcoLP__seeProduct">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__gloves.png"
              alt="Luvas Trek 100"
            />
            <a
              href="https://www.decathlon.com.br/luvas-trekking-viagem-explor-100-preto/p"
              className="EcoLP__goToProduct center"
            >
              Ver Produto
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Fleece
