const EcoDye = () => {
  return (
    <div className="Eco__dye">
      <div className="Eco__dye--title">
        <h2 className="Eco__dye--titleText">
          O desenvolvimento sustentável da jaqueta acontece em dois momentos:
        </h2>
      </div>
      <div className="Eco__grid">
        <div className="Eco__dye--left">
          <img
            className="Eco__image"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__dye--banner1.png"
            alt="Processo de tingimento"
          />
          <div className="Eco__dye--grid">
            <div className="Eco__dye--content">
              <p className="Eco__paragraph">
                O revestimento interno da jaqueta Trek 100 é cinza porque o
                tecido que usamos é uma mistura de linha preta tingida em
                solução com linha não tingida, reduzindo assim as emissões de
                CO2 em 40%. Comparado com um tecido tingido padrão, esse
                processo nos permite:
              </p>
              <ul className="Eco__list">
                <li className="Eco__list--item">
                  reduzir a emissão de partículas finas (PM2,5) em 66%
                </li>
                <li className="Eco__list--item">
                  reduzir a eutrofização da água doce em 74%
                </li>
                <li className="Eco__list--item">
                  reduzir a eutrofização da água salgada em 68%
                </li>
              </ul>
            </div>
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__dye--banner2.png"
              className="Eco__dye--image"
              alt="Processos"
            />
          </div>
        </div>
        <div className="Eco__dye--right">
          <img
            className="Eco__image"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__dye--banner3.png"
            alt="Poliéster Reciclado"
          />
          <p className="Eco__paragraph">
            O enchimento da Jaqueta Trek 100 é feito com 25% poliéster
            reciclado, a partir de garrafas plásticas. Ao escolhermos o
            poliéster reciclado, evitamos novos usos de recursos derivados do
            petróleo.
          </p>
        </div>
      </div>
      <div className="Eco__video">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/14EeAYb63b8"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={false}
          title="Processo Eco Concecpção"
        />
      </div>
    </div>
  )
}

export default EcoDye
