const Sh100 = () => {
  return (
    <>
      <div id="SH100" className="Eco__product">
        <h2 className="Eco__title">Jaqueta SH100</h2>
        <div className="Eco__grid">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner4--desk.png"
            alt="Jaqueta SH na neve"
          />
          <div className="Eco__product--content">
            <p className="Eco__paragraph">
              A jaqueta SH100 é ideal para a prática de trilha no frio. Protege
              da chuva e do vento, pois é impermeável, e mantém o corpo aquecido
              em temperaturas de até -10º
            </p>
            <p className="Eco__paragraph">
              É considerada também um produto sustentável porque o seu
              enchimento é feito com 100% de poliéster reciclado. Desta forma,
              ao reciclar garrafas plásticas ou usarmos têxteis reciclados para
              produzir o nosso poliéster, reduzimos o uso de recursos
              petrolíferos, preservando a respirabilidade do material durante a
              prática de exercício.
            </p>
            <img
              className="Eco__image"
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__product3.png"
              alt="Jaqueta SH 100"
            />
            <a
              href="https://www.decathlon.com.br/pesquisa?q=jaqueta-sh100"
              className="Eco__goToProduct right"
            >
              ver produto
            </a>
          </div>
        </div>
        <span className="Eco__paragraph bigger">
          Veja o vídeo que explica um pouco mais sobre o poliéster reciclado:
        </span>
        <div className="Eco__video">
          <iframe
            width="100%"
            height="600"
            src="https://www.youtube.com/embed/FwlP51NIAoo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={false}
            title="Produção de poliester reciclado"
          />
        </div>
      </div>
      <section id="SH100" className="EcoLP__product">
        <h2 className="EcoLP__title">Jaqueta SH100</h2>
        <img
          className="EcoLP__image"
          alt="Jaqueta SH na neve"
          src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner4.png"
        />
        <p className="EcoLP__paragraph">
          A jaqueta SH100 é ideal para a prática de trilha no frio. Protege da
          chuva e do vento, pois é impermeável, e mantém o corpo aquecido em
          temperaturas de até -10º.
        </p>
        <p className="EcoLP__paragraph">
          É considerada também um produto sustentável porque o seu enchimento é
          feito com 100% de poliéster reciclado. Desta forma, ao reciclar
          garrafas de plástico ou usarmos têxteis parcialmente reciclados para
          produzir o nosso poliéster, reduzimos o uso de recursos petrolíferos,
          preservando a respirabilidade do material durante a prática de
          exercício.
        </p>
        <div className="EcoLP__seeProduct">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__product3.png"
            alt="Jaqueta Trek 100"
          />
          <a
            href="https://www.decathlon.com.br/pesquisa?q=jaqueta-sh100"
            className="EcoLP__goToProduct right"
          >
            Ver Produto
          </a>
        </div>
        <p className="EcoLP__paragraph">
          Veja o vídeo que explica um pouco mais sobre o poliéster reciclado:
        </p>
        <div className="EcoLP__video">
          <iframe
            width="100%"
            height="220"
            src="https://www.youtube.com/embed/FwlP51NIAoo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={false}
            title="Produção de poliester reciclado"
          />
        </div>
      </section>
    </>
  )
}

export default Sh100
