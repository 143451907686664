const EcoGridMob = () => {
  return (
    <>
      <p className="EcoLP__paragraph">
        <strong className="EcoLP__strong">
          Como especialistas em esporte,
        </strong>
        nossa missão é desenvolver produtos de alto desempenho para tornar a sua
        prática ainda mais inesquecível. Mas, além disso, também nos
        comprometemos em desenvolver produtos que tem como premissa respeitar o
        meio ambiente e assim, preservar o nosso planeta.
      </p>
      <p className="EcoLP__paragraph">
        Por isso, aqui na Decathlon, trabalhamos com o conceito de ecodesign,
        que consiste em integrar o meio ambiente a partir do design de um
        produto levando em consideração todo o seu ciclo de vida, garantindo as
        mesmas funções para o uso deste produto.
      </p>
      <img
        className="EcoLP__image"
        src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner1.png"
        alt=""
      />
      <p className="EcoLP__paragraph">
        É pelo respeito ao meio ambiente que nossas equipes da Quechua e Forclaz
        trabalham continuamente para melhorar os processos de produção e reduzir
        ainda mais o impacto ambiental, com o objetivo de oferecer produtos
        duráveis e ambientalmente responsáveis.
      </p>
    </>
  )
}

export default EcoGridMob
