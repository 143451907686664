interface EcoAnchorProps {
  handleScroll: () => void
}

const EcoAnchor = ({ handleScroll }: EcoAnchorProps) => {
  return (
    <>
      <div className="Eco__anchor">
        <div
          onClick={handleScroll}
          data-id="Trek100"
          className="Eco__anchor--item"
          aria-hidden="true"
        >
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icon--trek100.png"
            className="Eco__anchor--image"
            alt="trek100 | Decathlon"
          />
          <span className="Eco__anchor--text">Jaqueta Trek 100</span>
        </div>
        <div data-id="Fleece" className="Eco__anchor--item">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icon--fleece.png"
            className="Eco__anchor--image"
            alt="fleece | Decathlon"
          />
          <span className="Eco__anchor--text">Fleece MH 100</span>
        </div>
        <div data-id="SH100" className="Eco__anchor--item">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icon--sh100.png"
            className="Eco__anchor--image"
            alt="sh100 | Decathlon"
          />
          <span className="Eco__anchor--text">Jaqueta SH 100</span>
        </div>
      </div>
      <div className="EcoLP__anchor">
        <div className="EcoLP__anchor--item" data-id="trek100">
          <img
            className="EcoLP__anchor--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icon--trek100.png"
            alt="trek100 | Decathlon"
          />
          <span className="EcoLP__anchor--text">Jaqueta Trek 100</span>
        </div>
        <div className="EcoLP__anchor--item" data-id="fleece">
          <img
            className="EcoLP__anchor--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icon--fleece.png"
            alt="fleece | Decathlon"
          />
          <span className="EcoLP__anchor--text">Fleece MH 100</span>
        </div>
        <div className="EcoLP__anchor--item" data-id="SH100">
          <img
            className="EcoLP__anchor--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__icon--sh100.png"
            alt="sh100 | Decathlon"
          />
          <span className="EcoLP__anchor--text">Jaqueta SH 100</span>
        </div>
      </div>
    </>
  )
}

export default EcoAnchor
