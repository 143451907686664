interface EcoFooterBannerProps {
  className: string
  mob?: string
}

const EcoFooterBanner = ({ className, mob }: EcoFooterBannerProps) => {
  return (
    <div id={mob} className={className}>
      <a
        href="https://www.decathlon.com.br/pesquisa?pageType=hotsite&q=invnova-12-05"
        className="Eco__footerBanner--link"
      >
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/20220510-lp-inverno_pagina-eco.png?v=637884378427300000"
          className="Eco__footerBanner--image"
          alt="Prepare-se para o frio"
        />
      </a>
    </div>
  )
}

export default EcoFooterBanner
