import { useEffect, useRef } from 'react'

interface Trek100Props {
  handleScrollDown: boolean
}

const Trek100 = ({ handleScrollDown }: Trek100Props) => {
  const element = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (handleScrollDown) {
      element?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [handleScrollDown])

  return (
    <>
      <div ref={element} id="Trek100" className="Eco__product">
        <h2 className="Eco__title">Jaqueta Trek 100</h2>
        <div className="Eco__grid">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner2--desk.png"
            alt="Jaqueta Trek Trilha"
          />
          <div className="Eco__product--content">
            <p className="Eco__paragraph">
              Perfeita para a prática de trilha durante o frio, a jaqueta Trek
              100 tem tecido resistente a abrasão e passa por um tratamento
              especial na superfície para repelir a água, o que a torna fácil de
              lavar.
            </p>
            <p className="Eco__paragraph">
              Além disso, seu enchimento é feito com poliéster reciclado, e
              apesar da sua fina espessura, promove o aquecimento necessário
              para o esportista.
            </p>
            <img
              className="Eco__image"
              src="https://decathlonstore.vteximg.com.br/arquivos/Eco__product1.png"
              alt="Jaqueta Trek 100"
            />
            <a
              href="https://www.decathlon.com.br/pesquisa/?q=jaqueta-trek-100"
              className="Eco__goToProduct right"
            >
              ver produto
            </a>
          </div>
        </div>
      </div>
      <section id="trek100" className="EcoLP__product">
        <h2 className="EcoLP__title">Jaqueta Trek 100</h2>
        <img
          className="EcoLP__image"
          alt=""
          src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner2.png"
        />
        <p className="EcoLP__paragraph">
          Perfeita para a prática de trilha durante o frio, a jaqueta Trek 100
          tem tecido resistente a abrasão e passa por um tratamento especial na
          superfície para repelir a água, o que a torna fácil de lavar.
        </p>
        <p className="EcoLP__paragraph">
          Além disso, seu enchimento é feito com poliéster reciclado, e apesar
          da sua fina espessura, promove o aquecimento necessário para o
          esportista.
        </p>
        <div className="EcoLP__seeProduct">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__product1.png"
            alt="Jaqueta Trek 100"
          />
          <a
            href="https://www.decathlon.com.br/pesquisa/?q=jaqueta-trek-100"
            className="EcoLP__goToProduct right"
          >
            Ver Produto
          </a>
        </div>
        <div className="EcoLP__dye">
          <div className="EcoLP__dye--title">
            <h2 className="EcoLP__dye--titleText">
              O desenvolvimento sustentável da jaqueta acontece em dois
              momentos:
            </h2>
          </div>
          <img
            className="EcoLP__dye--image"
            alt="processo de tingimento"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__dye--banner1.png"
          />
          <div className="EcoLP__dye--flex">
            <div className="EcoLP__dye--text">
              <p className="EcoLP__paragraph">
                O revestimento interno da jaqueta Trek 100 é cinza porque o
                tecido que usamos é uma mistura de linha preta tingida em
                solução com linha não tingida, reduzindo assim as emissões de
                CO2 em 40%. Comparado com um tecido tingido padrão, esse
                processo nos permite:
              </p>
              <ul className="EcoLP__list">
                <li className="EcoLP__list--item">
                  reduzir a emissão de partículas finas (PM2,5) em 66%
                </li>
                <li className="EcoLP__list--item">
                  reduzir a eutrofização da água doce em 74%
                </li>
                <li className="EcoLP__list--item">
                  reduzir a eutrofização da água salgada em 68%
                </li>
              </ul>
            </div>
            <div className="EcoLP__dye--images">
              <img
                className="EcoLP__dye--imageProcess"
                src="https://decathlonstore.vteximg.com.br/arquivos/Eco__banner2--desk.png"
                alt=""
              />
            </div>
          </div>
          <img
            className="EcoLP__dye--image"
            alt="Poliéster Reciclado"
            src="https://decathlonstore.vteximg.com.br/arquivos/Eco__dye--banner3.png"
          />
          <p className="EcoLP__paragraph">
            O enchimento da Jaqueta Trek 100 é feito com 25% poliéster
            reciclado, a partir de garrafas plásticas. Ao escolhermos o
            poliéster reciclado, evitamos novos usos de recursos derivados do
            petróleo.
          </p>
          <div className="EcoLP__video">
            <iframe
              width="100%"
              height="220"
              src="https://www.youtube.com/embed/14EeAYb63b8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
              title="video camisa fleece"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default Trek100
