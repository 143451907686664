import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation, Pagination } from 'swiper'

interface BlogPops {
  title: string
  description: string
  posts: Array<{
    link: string
    image: string
    title: string
    description: string
  }>
}

const Blog = ({ title, description, posts }: BlogPops) => {
  return (
    <section className="blog">
      <div className="av-container">
        <div className="av-row">
          <div className="section-title">
            <h2 className="section-title__text">{title}</h2>
          </div>
          <p className="texto-blog">{description}</p>
        </div>
      </div>
      <div className="av-container">
        <div className="av-row">
          <div className="section08-blog-post-content">
            <Swiper
              className="slick-slide-swiper"
              slidesPerView={1}
              pagination={{ clickable: true }}
              breakpoints={{
                1244: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                425: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
              lazy={{
                loadPrevNext: true,
                loadPrevNextAmount: 1,
              }}
              modules={[Navigation, Lazy, Pagination]}
            >
              {posts.map((post, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="post">
                      <a href={post.link}>
                        <img src={post.image} alt="Saber Mais | Decathlon" />
                        <div className="texto-post">
                          <p className="titulo-post">{post.title}</p>
                          <p className="conteudo-post">{post.description}</p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
