import { useEffect, useState } from 'react'

import Blog from '../../Blog'
import BannerMob from './BannerMob'
import EcoAnchor from './EcoAnchor'
import EcoDye from './EcoDye'
import EcoFooterBanner from './EcoFooterBanner'
import EcoGrid from './EcoGrid'
import EcoGridMob from './EcoGridMob'
import Fleece from './Fleece'
import Sh100 from './Sh100'
import './styles.scss'
import Trek100 from './Trek100'

const posts = [
  {
    link: 'https://souesportista.decathlon.com.br/roupas-de-frio-para-inverno-3-camadas/',
    image:
      'https://decathlonstore.vteximg.com.br/arquivos/Eco__Tips--Image1.png',
    title: 'ROUPAS DE FRIO PARA SE AQUECER NO INVERNO – AS 3 CAMADAS',
    description:
      'Quando o frio chega, bate aquela tristeza de largar o edredom quentinho e sair por aí trilhando, não é? Calma, o inverno não pode (e não vai) ser um [...]',
  },
  {
    link: 'https://souesportista.decathlon.com.br/nosso-centro-de-producao-global/',
    image:
      'https://decathlonstore.vteximg.com.br/arquivos/Eco__Tips--Image2.png',
    title: 'Nosso centro de produção global',
    description:
      'Nosso centro de design global está localizado sobre os pés da montanha Mont Blanc, à 20 km de Chamonix, na França. Este local é um verdadeiro ponto de [...]',
  },
  {
    link: 'https://souesportista.decathlon.com.br/jaqueta-impermeavel-como-testar/',
    image:
      'https://decathlonstore.vteximg.com.br/arquivos/Eco__Tips--Image3.png',
    title: 'JAQUETA IMPERMEÁVEL DE TRILHA: COMO TESTAR SUA IMPERMEABILIDADE?',
    description:
      'Um produto é chamado de impermeável quando ele tem a capacidade de impedir a entrada de água, protegendo assim o caminhante do mau tempo. Você [...]',
  },
]

const EcoDesignMontanhaComponent = () => {
  const [scrollDown, setScrollDown] = useState(false)

  useEffect(() => {
    scrollDown && setScrollDown(false)
  }, [scrollDown])

  return (
    <div className="Eco">
      <div id="desk" className="Eco__fullBanner">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/banner-produtos-sustentaveis-montanha_desk.jpg"
          alt="Eco concepção"
        />
      </div>
      <div id="desk" className="Eco__wrapper">
        <EcoGrid />
        <EcoAnchor
          handleScroll={() => {
            setScrollDown(true)
          }}
        />
        <Trek100 handleScrollDown={scrollDown} />
        <EcoDye />
        <Fleece />
        <Sh100 />
        <Blog
          title="Dicas Esportivas"
          description="Confira nossas dicas para seu esporte de montanha:"
          posts={posts}
        />
        <a
          href="https://souesportista.decathlon.com.br/dicas/"
          className="Eco__goToTips"
        >
          Veja todas as dicas
        </a>
        <EcoFooterBanner className="Eco__footerBanner" />
      </div>
      <div id="mob" className="EcoLP__wrapper">
        <BannerMob />
        <EcoGridMob />
        <EcoAnchor
          handleScroll={() => {
            setScrollDown(true)
          }}
        />
        <Trek100 handleScrollDown={scrollDown} />
        <Fleece />
        <Sh100 />
        <Blog
          title="Dicas Esportivas"
          description="Confira nossas dicas para seu esporte de montanha:"
          posts={posts}
        />
        <a
          className="EcoLP__seeTips"
          target="_blank"
          href="https://souesportista.decathlon.com.br/dicas/"
          rel="noreferrer"
        >
          Ver Todas as dicas
        </a>
      </div>
      <EcoFooterBanner mob="mob" className="EcoLP__footerBanner" />
    </div>
  )
}

export default EcoDesignMontanhaComponent
